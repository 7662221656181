<template>
  <v-container fluid>
    <v-row align="center" class="list mx-auto">
      <v-col cols="12" sm="12" class="pa-0 ma-0">

        <v-row class="mt-2">

          <div class="d-flex flex-wrap justify-center mb-2">
            <div class="pl-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                @keydown.enter="onSearch"
              ></v-text-field>
            </div>
            
            <div class="pl-2">
              <v-select
                v-model="pageSize"
                :items="pageSizes"
                label="Items per Page"
                @change="handlePageSizeChange"
              ></v-select>
            </div>

            <div class="pl-2">
              <v-pagination
                v-if="totalPages"
                v-model="page"
                :length="totalPages"
                total-visible="5"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
                @input="handlePageChange"
              ></v-pagination>
            </div>
            <div class="pl-2">
              <div class="flex">
                <v-btn fab dark color="blue" small @click="addDocument">
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="pl-2 mt-1" v-if="String(documentTypeName).toLowerCase() == 'readingplan'" color="primary">
              <v-icon color="green">mdi-sync</v-icon>
              <ReadingPlanSync
                text="Sync To CouchDB"
              />
            </div>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" class="mb-2">
        <v-card class="mx-auto" tile>
          <v-card-title v-if="showTitle">{{ documentTypeName }}</v-card-title>

          <v-data-table
            v-if="documents"
            :loading="loading"
            :headers="headers"
            :items="items"
            disable-pagination
            :hide-default-footer="true"
            mobile-breakpoint="0"
          >
            <template #item.title="{ item }">
              <router-link 
                :to="{ name: 'document_home', params: { documentid: item.id } }">
                {{ item.title }}
              </router-link>
            </template>
          </v-data-table>

        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapState } from 'vuex'
import ReadingPlanSync from '@/components/adminSettings/ReadingPlanSync'

export default {
  name: 'DocumentListing',
  components: {
    ReadingPlanSync
  },
  props: {
    documentTypeId: {
      type: Number,
      default: null
    },
    documentTypeName: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: true,
      options: {},
      items: [],
      search: '',
      totalResults: -1,
      headers: [
        { text: 'Id', align: 'start', sortable: true, value: 'id' },
        { text: 'Title', sortable: true, value: 'title' }
      ],
      page: 1,
      totalPages: 0,
      pageSize: localStorage.getItem('dataTablePageSize') ? parseInt(localStorage.getItem('dataTablePageSize')) : 100,
      pageSizes: [10, 20, 30, 40, 50, 100],
      offset: 0
    }
  },
  computed: {
    ...mapState('documents', ['documents', 'document'])
  },
  // watch: {
  //   options: {
  //     handler () {
  //       this.retrieveDocuments()
  //     },
  //     deep: true
  //   }
  // },
  mounted() {
    this.retrieveDocuments()
  },
  methods: {
    getRequestParams(search, page, pageSize, offset) {
      return {
        search: search ? search : null,
        page: page ? page - 1 : null,
        offset: page && pageSize ? (page - 1) * pageSize : 0,
        limit: pageSize ? pageSize : 100,
        ordering: 'slug'
      }
    },
    retrieveDocuments() {
      const params = this.getRequestParams(
        this.search,
        this.page,
        this.pageSize,
        this.offset
      )
      
      params.documenttypeid = this.documentTypeId

      this.$store.dispatch('documents/getDocuments', params).then((d) => {
        this.setData(d)
        this.loading = false
      })
    },
    handlePageChange(value) {
      // localStorage.setItem('dataTablePage', parseInt(value))
      this.page = value
      this.retrieveDocuments()
    },
    handlePageSizeChange(size) {
      localStorage.setItem('dataTablePageSize', parseInt(size))
      this.pageSize = size
      this.page = 1
      this.retrieveDocuments()
    },
    getDisplayDocument(document) {
      return {
        id: document.documentid,
        title: document.englishtitle
      }
    },
    setData(data) {
      this.items = data.results.map(this.getDisplayDocument)
      this.totalPages = Math.round(data.count / this.pageSize)
    },
    onSearch(event) {
      event.preventDefault()
      this.retrieveDocuments()
    },
    addDocument() {
      return this.$router.push({
        name: 'document_add',
        params: {
          documentid: document.documentid
        },
        query: {
          documenttype: this.documentTypeName
        }
      })
    }
  }
}
</script>