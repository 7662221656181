<template>
  <v-card>
    <p>Jay Shree Swaminarayan {{ name }}</p>
    <p>Some dashy stuff to come here.</p>
    <p>groups={{ groups }}</p>
    <p>isSuperUser={{ isSuperUser }}</p>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardPage',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('languages', ['languages']),
    ...mapState('account', ['user', 'groups', 'isSuperUser']),
    ...mapState('documents', ['documentTypes']),
    name() {
      if (!this.user) {
        return null
      }

      const fname = this.user.first_name ? this.user.first_name : null
      const lname = this.user.last_name ? this.user.last_name : null

      if (fname && lname) {
        return `${fname} ${lname}`
      }

      if (fname) {
        return fname
      }

      return 'Bhagatji'
    }
  }
}
</script>
