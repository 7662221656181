<template>
  <v-card v-if="documentTypeAllowed.length" tile flat>
    <v-tabs v-model="documentTabs" background-color="primary" dark show-arrows>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab v-for="item in documentTypeAllowed" :key="item.name" :href="`#${item.name}`">
        <v-icon left small class="mr-1">
          {{ utils.getDocumentTypeIcon(item.name) }}
        </v-icon>
        {{ item.name }}S
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="documentTabs">
      <v-tab-item v-for="item in documentTypeAllowed" :key="item.name" :value="item.name">
        <DocumentListing :document-type-id="item.documenttypeid" :document-type-name="item.name" />
      </v-tab-item>
    </v-tabs-items>

  </v-card>
  <v-alert v-else prominent type="error">
    Access is denied, you do not have the required groups to access this, your group: {{ groups }}
  </v-alert>
</template>

<script>
import DocumentListing from '@/components/documents/DocumentListing'
import { mapState, mapGetters } from 'vuex'
import utils from '@/helpers/utils'

export default {
  name: 'DocumentsLanding',
  components: {
    DocumentListing
  },
  data: () => ({
    utils
  }),
  computed: {
    ...mapState('account', ['groups', 'isSuperUser']),
    ...mapGetters('account', ['allowRead']),
    ...mapState('documents', ['documentTypes']),
    documentTabs: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    },
    documentTypeAllowed() {
      if (!this.documentTypes) {
        return []
      }

      if (!this.documentTypes && (!this.groups && !this.isSuperUser)) {
        return []
      }
      
      if (this.isSuperUser) {
        return this.documentTypes
      }

      return this.documentTypes.filter((documentType) => {
        return this.allowRead(documentType.name)
      })
    }
  },
  async created() {
    if (this.documentTypes && this.documentTypes.length) { return }
    await this.$store.dispatch('documents/getDocumentTypes')
  }
}
</script>